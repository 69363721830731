/* global ajax_object */
/* global Foundation */

// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; import './autoload/lazyload.js'; // eslint-disable-line

// Import local dependencies
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import LocomotiveScroll from 'locomotive-scroll';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import Player from '@vimeo/player';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

let scroll;

/**
 * Add / Remove attribute for courses sidebar
 */
function sticky_course_sidebar() {
  let $coursesSidebar = $('.js-courses-sidebar');

  if ($(window).width() >= 768) {
    if (!$coursesSidebar.hasClass('data-sticky')) {
      $coursesSidebar.addClass('data-sticky').attr({
        'data-scroll': '',
        'data-scroll-sticky': '',
        'data-scroll-offset': '-15%, 15%',
        'data-scroll-target': '#fixed-sidebar',
        'data-scroll-speed': '0.5',
      });
    }
  } else if ($(window).width() < 768) {
    if ($coursesSidebar.hasClass('data-sticky')) {
      $coursesSidebar
        .removeClass('data-sticky')
        .removeAttr(
          'data-scroll data-scroll-sticky data-scroll-offset data-scroll-target data-scroll-speed style'
        );
    }
  }
}

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * History slider function
 */
function historySlider() {
  let $historySlider = $('.js-history-slider'),
    $historyTimeline = $('.js-history-timeline');

  if ($historySlider.length) {
    $historySlider.slick({
      cssEase: 'ease',
      // centerMode: true,
      fade: false, // Cause trouble if used slidesToShow: more than one
      infinite: false,
      speed: 500,
      pauseOnHover: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 1, // Prevent generating extra markup
      asNavFor: $historyTimeline,
    });

    $historyTimeline.slick({
      cssEase: 'ease',
      fade: false, // Cause trouble if used slidesToShow: more than one
      infinite: false,
      arrows: false,
      speed: 500,
      pauseOnHover: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      focusOnSelect: true,
      rows: 0, // Prevent generating extra markup
      asNavFor: $historySlider,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    });
  }
}

/**
 * Schedule slider function
 */
function scheduleSlider() {
  let $scheduleSlider = $('.js-schedule-slider');

  if ($scheduleSlider.length) {
    $scheduleSlider.slick({
      cssEase: 'cubic-bezier(0.755, 0.155, 0.275, 0.865)',
      fade: false, // Cause trouble if used slidesToShow: more than one
      infinite: false,
      speed: 500,
      pauseOnHover: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      rows: 1, // Prevent generating extra markup
      prevArrow: $('.js-arrow-prev'),
      nextArrow: $('.js-arrow-next'),
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 641,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }
}

/**
 * Gallery slider function
 */
function gallerySlider() {
  let $gallerySlider = $('.js-gallery-slider');

  if ($gallerySlider.length) {
    $gallerySlider.slick({
      cssEase: 'ease',
      fade: false, // Cause trouble if used slidesToShow: more than one
      infinite: false,
      speed: 500,
      pauseOnHover: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 1, // Prevent generating extra markup
    });
  }
}

/**
 * Intro Side Nav mobile styles
 */

let introSideNav = function () {
  $('.js-intro-nav').each(function () {
    let $lists = $('.js-intro-nav');
    $lists.each(function () {
      let $list = $(this);
      $list.css('height', 'auto');
      let currentHeight = $(this).outerHeight(true, true);
      let sum = 0;
      $list.find('li:lt(1)').each(function () {
        sum += $(this).outerHeight(true, true);
      });
      $list.attr('data-height', currentHeight);
      if ($(window).width() < 768) {
        $list.css('height', sum + 'px');
        $list.attr('data-height-closed', sum);
      } else {
        $list.css('height', 'auto');
      }
      $('.js-side-nav-toggle').removeClass('active');
    });
  });
};
$('.js-side-nav-toggle').on('click', function () {
  let openedHeight = $(this).siblings('.js-intro-nav').data('height');
  let closedHeight = $(this).siblings('.js-intro-nav').data('height-closed');
  if ($(this).hasClass('active')) {
    $(this).siblings('.js-intro-nav').css('height', closedHeight);
  } else {
    $(this).siblings('.js-intro-nav').css('height', openedHeight);
  }
  $(this).toggleClass('active');
});

/**
 * Counters animation count
 */
function animatedCounters() {
  let $counter = $('.js-counter');
  if ($counter.length > 0) {
    $counter.each(function (i, el) {
      if ($(el).hasClass('is-inview') && !$(el).hasClass('counted')) {
        $(el).addClass('counted');
        var $this = $(this),
          countTo = $(el).attr('data-count');
        setTimeout(function () {
          $({ countNum: $(el).text() }).animate(
            {
              countNum: countTo,
            },
            {
              duration: 1500,
              easing: 'swing',
              step: function () {
                $this.text(Math.floor(this.countNum));
              },
              complete: function () {
                $this.text(this.countNum);
              },
            }
          );
        }, 300);
      }
    });
  }
}

/**
 * Filter Courses by Category
 */
function coursesFilter() {
  let $coursesFilters = $('.js-course-category').find('input[type="checkbox"]'),
    $wrapper = $('.js-courses-wrap');

  $coursesFilters.on('change', function () {
    // Get values from checkboxes
    $wrapper.addClass('loading');
    let values = [];
    $coursesFilters.each(function () {
      if ($(this).is(':checked')) {
        values.push($(this).val());
      }
    });

    // Filtering Courses
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      dataType: 'json',
      data: {
        action: 'courses_filtering',
        values: values,
      },
    }).done(function (results) {
      $wrapper.html(results.template);
      setTimeout(function () {
        $wrapper.removeClass('loading');
      }, 300);
      Foundation.reInit('accordion');
      scroll.update();
    });
  });
}

// Calc height for pseudo element
function calcHeight($section) {
  let sectionOffset = $section.offset().top,
    $img = $section.find('.js-img'),
    imgOffset = $img.offset().top,
    imgHeight = $img.outerHeight(),
    beforeHeight = imgOffset + imgHeight / 2 - sectionOffset;

  return beforeHeight;
}

// Wrap table to container in
function tableWrap() {
  let $table = $('.centered-content__container ').find('table');
  if ($table.length) {
    $table.wrap('<div class="centered-content__table"></div>');
  }
}

// Css dynamic variables
function cssDynamicVars() {
  // Quote padding top
  if ($('.quote').length) {
    let paddingTop = $('.quote--blue .quote__image').width() / 2;
    document.documentElement.style.setProperty('--pt', `${paddingTop}px`);
  }

  // Header height
  let headerHeight = $('.header').outerHeight();
  document.documentElement.style.setProperty(
    '--headerHeight',
    `${headerHeight}px`
  );

  // Footer Cta before
  let footerCta = $('.footer__cta').outerHeight() + 15;
  document.documentElement.style.setProperty('--footerCta', `${footerCta}px`);

  // Centered Content before height
  if ($('.centered-content.blue-gr').length) {
    let artBeforeHeight = calcHeight($('.centered-content.blue-gr'));
    document.documentElement.style.setProperty(
      '--gradientHeight',
      `${artBeforeHeight}px`
    );
  }

  // Articles before height
  if ($('.articles').length) {
    let artBeforeHeight = calcHeight($('.articles'));
    document.documentElement.style.setProperty(
      '--artBeforeHeight',
      `${artBeforeHeight}px`
    );
  }

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * Add Icon to text link
   */
  $('.link').append(
    '<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 11L6 6L1 1" stroke="currentColor" stroke-linejoin="bevel"/></svg>'
  );

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  $('[data-fancybox]').fancybox({
    mobile: {
      clickSlide: false,
      clickOutside: false,
      touch: false,
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
    scroll.update();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  if ($('.contact-form__container').length) {
    let formOffset = $('.contact-form__container').offset().top;
    $(document).on('gform_confirmation_loaded', function () {
      scroll.update();
      scroll.scrollTo(formOffset, 0, 500);
    });

    $(document).on('gform_page_loaded', function () {
      scroll.update();
      scroll.scrollTo(formOffset, 0, 500);
    });
  }

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('.header').toggleClass('active-menu');
      // if ($('.header').hasClass('active-menu')) {
      //   scroll.stop();
      // } else {
      //   scroll.start();
      // }
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $('.top-bar').removeClass('is-active');
    })
    .on('down.zf.accordion', function () {
      // scroll.update();
      // scroll.scrollTo(el.closest('.accordion-item')[0], {
      //   offset: -$('.header').outerHeight(),
      //   duration: 300,
      //   disableLerp: true,
      // });
    })
    .on('up.zf.accordion', function () {
      // scroll.update();
    })
    .on('change.zf.tabs', function () {
      // scroll.update();
    })
    .on('collapse.zf.tabs	', function () {
      // scroll.update();
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 1025) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  /**
   * Init History Slider
   */
  historySlider();

  /**
   * Init Schedule Slider
   */
  scheduleSlider();

  /**
   * Init Gallery Slider
   */
  gallerySlider();

  /**
   * Init Intro Side Nav styles
   */
  introSideNav();

  /**
   * Init Courses Filters
   */
  coursesFilter();

  /**
   * Init table wrap
   */
  tableWrap();

  /**
   * Init vimeo API
   */
  let activePlayers = [],
    $videos = $('.js-video'),
    playing = false;

  $videos.each(function () {
    let playerId = $(this).attr('id');
    activePlayers[playerId] = new Player($(this).find('iframe'));
  });

  $('body').on('click', '.js-video', function () {
    let activePlayer = null;
    if (playing == false && !$(this).hasClass('playing')) {
      $(this).addClass('playing');
      activePlayers[$(this).attr('id')].play();
      playing = true;
      activePlayer = $(this).attr('id');
    }

    let onPause = function () {
      $('.js-video').removeClass('playing');
      playing = false;
    };

    activePlayers[activePlayer].on('pause', onPause);
  });

  /**
   * Hide / Show search bar in header
   */
  $('.js-search-btn').on('click', function () {
    $(this).closest('.header__search-bar').toggleClass('active');
  });

  /**
   * Hover navigation item animation
   */
  $('#menu-main-menu .js-submenu').hover(
    function () {
      let $subMenu = $(this).find('.submenu'),
        offsetLeft = $subMenu.offset().left,
        bgWidth = $subMenu.outerWidth(),
        bgHeight = $subMenu.outerHeight();
      document.documentElement.style.setProperty('--bgLeft', `${offsetLeft}px`);

      $('.js-submenu-bg')
        .css({ width: bgWidth, height: bgHeight })
        .addClass('active');
    },
    function () {
      $('.js-submenu-bg').removeClass('active');
    }
  );

  /**
   * Init LocomotiveScroll
   */
  /*scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    smartphone: {
      smooth: true,
    },
    tablet: {
      smooth: true,
    },
    getSpeed: true,
    getDirection: true,
    useKeyboard: true,
    smoothMobile: true,
    multiplier: 0.8,
    touchMultiplier: 2,
    lerp: 0.095,
  });*/

  /*let prevScrollpos = 0;

  scroll.on('scroll', (obj) => {*/
  /**
   * Init Counters animation count
   */
  animatedCounters();

  /*let currentScrollPos = obj.scroll.y;

    if (prevScrollpos < currentScrollPos) {
      if (!$('.header').hasClass('active-menu')) {
        $('.header').addClass('hidden');
      }
    } else {
      $('.header').removeClass('hidden');
    }
    prevScrollpos = currentScrollPos;
  });*/

  // Dynamically offset
  cssDynamicVars();

  // Init sticky sidebar
  sticky_course_sidebar();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  // Dynamically offset
  cssDynamicVars();

  // Update LocomotiveScroll
  // scroll.update();
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();

  // Init intro Nav accordion
  introSideNav();

  // Dynamically offset
  cssDynamicVars();

  $('.matchHeight').matchHeight();

  // Init sticky sidebar
  sticky_course_sidebar();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
